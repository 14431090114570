<template>
  <div class="my-5">
    <v-card class="mt-5">
      <div class="mx-3 my-2">
        <h3>Imóveis Rurais</h3>
        <div class="my-2 mx-3" v-if="data.length == 0">
          Sem imóveis rurais cadastrados!
        </div>
        <div v-if="data.length > 0">
          <div class="mx-4 my-2" v-for="(property, index) in data" :key="index">
            <div v-if="data.length > 1">
              <h4>Imóvel rural ({{ index + 1 }} de {{ data.length }})</h4>
            </div>
            <div v-else>
              <h4>Imóvel rural (apenas 1)</h4>
            </div>
            <div>
              <v-row>
                <v-col cols="2">
                  <v-img
                    v-if="property.image_path"
                    :src="property.image_path"
                    style="width: 300px; height: 300px"
                  />
                  <v-img
                    v-else
                    src="/static/assets/no_area.png"
                    style="width: 300px; height: 300px"
                  />
                </v-col>
                <v-col cols="10">
                  <ul>
                    <li>Código Incra: {{ property.incra_code }}</li>
                    <li>Denominação: {{ property.property_name }}</li>
                    <li>Localidade: {{ property.location }}</li>
                    <li>Área Total: {{ property.total_area }} ha</li>
                    <li>Georreferenciamento: {{ property.georeferencing }}</li>
                  </ul>
                </v-col>
              </v-row>
            </div>
            <div class="mx" v-if="property.biomas_images.length > 0">
              <v-container>
                <v-row>
                  <v-col
                    v-for="(bioma_image, index) in property.biomas_images"
                    :key="index"
                    cols="4"
                  >
                    <h3>{{ bioma_image.year }}</h3>
                    <v-img
                      :src="bioma_image.image_path"
                      style="
                        width: 300px;
                        height: 300px;
                        background-color: transparent !important;
                        box-shadow: none !important;
                        border: 1px solid #000;
                      "
                    >
                    </v-img>
                  </v-col>
                </v-row>
              </v-container>
            </div>

            <div class="mx" v-if="property.biomas_caption.labels.length > 0">
              <v-container>
                <RuralChartSection
                  :caption="property.biomas_caption"
                  :colors="property.biomas_colors"
                >
                </RuralChartSection>
              </v-container>
            </div>

            <div
              style="max-width: 700px"
              v-if="property.predominant_cultures.length > 0"
            >
              <h3>Culturas Predominantes</h3>
              <table>
                <thead>
                  <tr>
                    <th>Ano</th>
                    <th>Cultura</th>
                    <th>Área (ha)</th>
                  </tr>
                </thead>
                <tbody
                  v-for="(culture, index) in property.predominant_cultures"
                  :key="index"
                >
                  <tr>
                    <td>{{ culture.year }}</td>
                    <td>{{ culture.label }}</td>
                    <td>{{ culture.area }}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="mx"
              v-if="
                property.sicars &&
                property.sicars.incra_area_geojson &&
                property.sicars.sicars_geojson
              "
            >
              <v-container>
                <RuralCARMapSection
                  :sicars_geojson="property.sicars.sicars_geojson"
                  :incra_area_geojson="property.sicars.incra_area_geojson"
                  :id="property.sicars.incra_area_geojson.properties.id"
                ></RuralCARMapSection>
              </v-container>
            </div>

            <div
              class="mx"
              v-if="
                property.sicars &&
                property.sicars.labels &&
                property.sicars.labels.length > 0
              "
            >
              <v-container>
                <RuralCARChartSection
                  :caption="property.sicars"
                ></RuralCARChartSection>
              </v-container>
            </div>

            <div
              v-if="
                property.sicars.sicars_table_data &&
                property.sicars.sicars_table_data.length > 0
              "
            >
              <h3>Dados CAR</h3>
              <v-data-table
                :headers="headers"
                :items="property.sicars.sicars_table_data"
                item-key="id"
                class="elevation-1"
              >
                <template v-slot:[`item.category`]="{ item }">
                  <span
                    :style="{
                      display: 'inline-block',
                      width: '20px',
                      height: '20px',
                      'background-color': getCategoryColor(
                        property,
                        item.category
                      ),
                    }"
                  ></span>
                  <span style="margin-left: 8px">{{ item.category }}</span>
                </template>
                <template v-slot:[`item.des_condic`]="{ item }">
                  <div style="padding: 12px 0">
                    {{ item.des_condic }}
                  </div>
                </template>
                <template v-slot:[`item.num_area`]="{ item }">
                  {{
                    item.num_area && item.num_area !== 0
                      ? item.num_area.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " ha"
                      : ""
                  }}
                </template>
                <template v-slot:[`item.mod_fiscal`]="{ item }">
                  {{
                    item.mod_fiscal && item.mod_fiscal !== 0
                      ? item.mod_fiscal.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }}
                </template>
              </v-data-table>
            </div>

            <h3>Proprietários</h3>
            <table>
              <thead>
                <tr>
                  <th>Dcto</th>
                  <th>Tipo Dcto</th>
                  <th>Nome</th>
                  <th>Nacionalidade</th>
                  <th>Condição</th>
                  <th class="right-align">Perc.Detenção</th>
                </tr>
              </thead>
              <tbody v-for="owner in property.owners" :key="owner.document">
                <tr :class="{ 'bold-row': document == owner.document }">
                  <td>{{ owner.document }}</td>
                  <td>{{ owner.type }}</td>
                  <td>{{ owner.name }}</td>
                  <td>{{ owner.nacionality }}</td>
                  <td>{{ owner.condition }}</td>
                  <td class="right-align">{{ owner.percentage }}</td>
                </tr>
              </tbody>
            </table>

            <h3>Registros</h3>
            <table>
              <thead>
                <tr>
                  <th>Data</th>
                  <th>CNS/Ofício</th>
                  <th>Município</th>
                  <th>Registro</th>
                  <th>Matricula/Transcrição</th>
                  <th>Livro/Ficha</th>
                  <th>Área</th>
                  <th class="right-align">Percentual</th>
                </tr>
              </thead>
              <tbody
                v-for="registry in property.registers"
                :key="registry.registration"
              >
                <tr>
                  <td>{{ registry.day }}</td>
                  <td>{{ registry.number }}</td>
                  <td>{{ registry.city }}</td>
                  <td>{{ registry.record }}</td>
                  <td>{{ registry.registration }}</td>
                  <td>{{ registry.book }}</td>
                  <td>{{ registry.area }}</td>
                  <td class="right-align">{{ registry.percentage }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </v-card>
  </div>
</template>

<style scoped>
.bold-row {
  font-weight: bold;
}
</style>

<script setup lang="ts">
import RuralChartSection from "@/modules/search/report_sections/RuralChartSection.vue";
import RuralCARChartSection from "@/modules/search/report_sections/RuralCARChartSection.vue";
import RuralCARMapSection from "@/modules/search/report_sections/RuralCARMapSection.vue";

interface BiomaImageData {
  year: number;
  image_path: string;
}

interface CaptionData {
  label: string;
  data: Array<number>;
  backgroundColor: string;
}

interface BiomaCaptionData {
  labels: Array<string>;
  datasets: Array<CaptionData>;
}

interface PredominantCulture {
  year: number;
  label: string;
  area: string;
}

interface OwnerData {
  document: string;
  type: string;
  name: string;
  nacionality: string;
  condition: string;
  percentage: string;
}

interface RegistryData {
  day: string;
  number: string;
  city: string;
  record: string;
  registration: string;
  book: string;
  area: string;
  percentage: string;
}

interface CARdata {
  id: number;
  category: string;
  cod_imovel: string;
  ind_status: string;
  num_area: number;
  ind_tipo: string;
  des_condic: string;
  mod_fiscal: number;
}

interface CARCaptionData {
  labels: Array<string>;
  datasets: Array<CaptionData>;
  sicars_table_data: Array<CARdata>;
  sicars_geojson: object;
  incra_area_geojson: object;
}

interface PropertyData {
  incra_code: string;
  property_name: string;
  location: string;
  georeferencing: string;
  image_path: string;
  biomas_images: Array<BiomaImageData>;
  biomas_caption: BiomaCaptionData;
  biomas_colors: object;
  predominant_cultures: Array<PredominantCulture>;
  total_area: string;
  owners: Array<OwnerData>;
  registers: Array<RegistryData>;
  sicars: CARCaptionData;
}

defineProps<{
  document: string;
  data: Array<PropertyData>;
}>();

const headers = [
  { title: "Contador", key: "id" },
  { title: "Categoria", key: "category" },
  { title: "Cod. CAR", key: "cod_imovel", width: "25%" },
  { title: "Situação", key: "ind_status" },
  { title: "Área total", key: "num_area" },
  { title: "Tipo", key: "ind_tipo" },
  { title: "Condição", key: "des_condic" },
  { title: "Módulos fiscais", key: "mod_fiscal" },
];

const getCategoryColor = (property: PropertyData, category: string): string => {
  const datasets = property?.sicars?.datasets || [];
  const dataset = datasets.find((d) => d.label === category);
  return dataset?.backgroundColor || "#000";
};
</script>
